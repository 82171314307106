import { css } from '@emotion/react';
import { V1Guest } from '@ennismore/ows-api-client';
import React, { useEffect, useState } from 'react';
import { useId } from 'react';

import { RoomWeeklyCalendarContainer } from '@/availability';
import { SearchResultRoomCard } from '@/availability/components/results';
import SearchResultRoomCardTitle from '@/availability/components/results/SearchResultRoomCardTitle.component';
import UnavailableRoomCardContent from '@/availability/components/results/UnavailableRoomCardContent.component';
import { IRoomAvailabilityInstance } from '@/availability/models/room-availability.model';
import { Flex } from '@/box';
import ResponsiveConditional from '@/common/components/responsive/ResponsiveConditional.component';
import { useFeatureSwitchStatus } from '@/features';
import HotelRoomSpecItem from '@/hox-core/components/HotelRoomSpecItem.component';
import HotelRoomSpecs from '@/hox-core/components/HotelRoomSpecs.component';
import { useTranslation } from '@/i18n';
import { Markdown } from '@/markdown';
import { Button, CollapsibleSection, ErrorMessage } from '@/ui/controls';
import { Divider } from '@/ui/layout';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import { IHotelModelInstance } from '../models';
import HotelRoomListItemRates from './HotelRoomListItemRates.component';

interface BedroomCardProps {
  isCreatingBooking?: boolean;

  handleRequestRoomSelectionReset: () => void;

  ctaText?: string;
  onCtaClick: (params: {
    isTwinSelected: boolean;
    selectedInternalRateCode: string;
    selectedRequestRateCode: string;
  }) => void;
  errorMessage?: string;

  onDateRangeChange: (start: string, end: string) => void;

  roomAvailabilityModel: IRoomAvailabilityInstance;
  hotelModel: IHotelModelInstance;
  accessibleRoomInformation?: React.ReactNode;

  checkInDate: string;
  checkOutDate: string;
  roomOccupants: V1Guest;

  /**
   * This will be set when the user is selecting a multi-room booking. Locks the rate selection to whatever was selected for the first room.
   */
  lockRateToRequestCode?: string;

  /**
   * Generally used to add a CTA button - Book Now, etc.
   */
  footer?: React.ReactNode[];
}

/**
 * This represents an availability result item.
 * There's a lot going on here and is in dire need of a refactor.
 * Ideally we would have different types of list item based on
 * whether or not it's available, and why.
 * @param props
 */
export const BedroomCard = ({
  roomAvailabilityModel,
  ...props
}: BedroomCardProps) => {
  const { t } = useTranslation('search');
  const [selectedVariantKey, setSelectedVariantKey] = useState<
    string | undefined
  >('regular');

  const { componentProperties } = useTheme();

  /**
   * Boolean reflecting whether the customer has selected the "Twin" tab at the top of the card.
   */
  const isTwinSelected = selectedVariantKey === 'twin';

  /**
   * Select the matching variant of the hotel room content
   */
  const selectedVariant =
    selectedVariantKey && roomAvailabilityModel.room.variations
      ? roomAvailabilityModel.room.variations[selectedVariantKey]
      : undefined;

  const { name, header, description, extras } =
    selectedVariant || roomAvailabilityModel.room;

  const initialSelectedRateCode =
    props.lockRateToRequestCode ||
    roomAvailabilityModel.defaultRateStayCost?.rateCode.requestRateCode ||
    'BAR';

  const [selectedRateCode, setSelectedRateCode] = React.useState<string>(
    initialSelectedRateCode
  );

  useEffect(() => {
    if (props.lockRateToRequestCode) {
      setSelectedRateCode(props.lockRateToRequestCode);
    }
  }, [props.lockRateToRequestCode]);

  const selectedRate = roomAvailabilityModel.availability.find(
    (avail) => avail.rateCode.requestRateCode === selectedRateCode
  );

  const selectedInternalRateCode =
    selectedRate?.rateCode.internalRateCode || selectedRateCode;

  const isAvailable = selectedRate?.bookable.value;

  const headingId = useId();
  const featureHideCalendar = useFeatureSwitchStatus('hide-weekly-calendar');
  const isCalendarAvailable =
    roomAvailabilityModel.stayCostForRoomRate(selectedRateCode)
      ?.isCalendarAvailable && !featureHideCalendar;

  return (
    <SearchResultRoomCard
      roomAvailabilityModel={roomAvailabilityModel}
      aria-labelledby={headingId}
      testId="bedroom-result-card"
    >
      <Flex flexDirection="column" flex={1}>
        {/* Mute this till we need to reimplement */}
        {/* {isAvailable && roomAvailabilityModel.room.variations['twin'] ? (
          <>
            <TabControl
              tabs={[
                { label: 'Regular', key: 'regular' },
                { label: 'Twin Bed', key: 'twin' },
              ]}
              onSelect={(key) => setSelectedVariantKey(key)}
              activeTabKey={selectedVariantKey}
            />
            <Spacer s="xs"x />
          </>
        ) : null} */}

        <SearchResultRoomCardTitle
          name={name}
          subtitle={header}
          id={headingId}
        />

        <Spacer s="xs" />

        {isAvailable ? (
          <>
            <Divider />
            {extras.length > 0 ? (
              <>
                <HotelRoomSpecs
                  css={css({
                    padding: '16px 0',
                    ...componentProperties.bedroomCard?.roomSpecItemsList,
                  })}
                >
                  {extras.map((extra) => (
                    <HotelRoomSpecItem
                      label={extra.title || ''}
                      value={extra.text || ''}
                      id={extra.id}
                      key={extra.title + extra.id + extra.text}
                    />
                  ))}
                </HotelRoomSpecs>
                <Divider />
                <Spacer s="xs" />
              </>
            ) : undefined}

            <BodySmall className="Description">{description}</BodySmall>

            <Spacer s="xs" />

            {roomAvailabilityModel.room.accessibility && (
              <>
                <Divider />
                <Spacer s="xs" />
                <BodySmall>
                  <CollapsibleSection
                    linkText={t(
                      roomAvailabilityModel.room.accessibility.totalRooms === 1
                        ? 'roomCard.accessibleRoomsAvailable'
                        : 'roomCard.accessibleRoomsAvailable_plural',
                      {
                        accessibleRoomCount:
                          roomAvailabilityModel.room.accessibility.totalRooms,
                      }
                    )}
                  >
                    <Markdown
                      source={roomAvailabilityModel.room.accessibility.content}
                    />
                  </CollapsibleSection>
                </BodySmall>
                <Spacer s="xs" />
              </>
            )}

            {isCalendarAvailable && (
              <ResponsiveConditional
                breakpoint={1200}
                desktop={
                  <>
                    <Divider />
                    <Spacer s="xs" />
                    <RoomWeeklyCalendarContainer
                      roomAvailability={roomAvailabilityModel}
                      selectedRateCode={selectedRateCode}
                      checkInDate={props.checkInDate}
                      checkOutDate={props.checkOutDate}
                      hotelReferenceId={props.hotelModel.referenceId}
                      roomOccupants={props.roomOccupants}
                      currencyCode={props.hotelModel.payments.currencyCode}
                      onDateRangeChange={props.onDateRangeChange}
                    />
                    <Spacer s="m" />
                  </>
                }
              />
            )}
            <div>
              {/* Pricing */}
              <div>
                {/*
                  If there's multiple room rates available, allow user to pick between them.
                  Otherwise if there's just one, render a single totals component
                */}
                <HotelRoomListItemRates
                  roomAvailabilityModel={roomAvailabilityModel}
                  onSelectRoomRate={setSelectedRateCode}
                  onRequestRoomSelectionReset={
                    props.handleRequestRoomSelectionReset
                  }
                  selectedRequestRateCode={selectedRateCode}
                  isRateSwitchingLocked={Boolean(props.lockRateToRequestCode)}
                  hotel={props.hotelModel}
                  checkInDate={props.checkInDate}
                  checkOutDate={props.checkOutDate}
                />
              </div>
              <Spacer s="m" />
              <div>
                <Button
                  onClick={() =>
                    props.onCtaClick({
                      isTwinSelected,
                      selectedRequestRateCode: selectedRateCode,
                      selectedInternalRateCode,
                    })
                  }
                  isLoading={props.isCreatingBooking}
                  testId="select-room-button"
                >
                  {props.ctaText || t('roomCard.bookCtaLabel')}
                </Button>
                {props.errorMessage && (
                  <>
                    <Spacer s="xxs" />
                    <ErrorMessage message={props.errorMessage} />
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <UnavailableRoomCardContent
            roomAvailabilityModel={roomAvailabilityModel}
            selectedRateCode={selectedRateCode}
            checkInDate={props.checkInDate}
            checkOutDate={props.checkOutDate}
            hotelReferenceId={props.hotelModel.referenceId}
            roomOccupants={props.roomOccupants}
            onDateRangeChange={props.onDateRangeChange}
          />
        )}
      </Flex>
    </SearchResultRoomCard>
  );
};
