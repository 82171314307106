import { css } from '@emotion/react';
import React from 'react';

import { useLanguage } from '@/i18n';

import { useTheme } from '../theme';

type GooglePayButtonType = 'book' | 'pay' | 'continue';

interface GooglePayButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  type?: GooglePayButtonType;
}

export const GooglePayButton: React.FC<GooglePayButtonProps> = ({
  onClick,
  isDisabled,
  type,
}) => {
  const language = useLanguage();
  const {
    componentProperties: { paymentMethods },
  } = useTheme();

  return (
    <button
      type="button"
      aria-label="Google Pay"
      onClick={onClick}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      css={css`
        background-image: url(${type === 'book'
          ? `https://www.gstatic.com/instantbuy/svg/${
              paymentMethods?.googlePay?.style ?? 'dark'
            }/book/${language}.svg`
          : `https://www.gstatic.com/instantbuy/svg/${
              paymentMethods?.googlePay?.style ?? 'dark'
            }_gpay.svg`});
        min-width: 161px;
        padding: 9px 15%;
        background-color: ${paymentMethods?.googlePay?.style === 'light'
          ? 'white'
          : 'black'};
        box-shadow: none;
        background-origin: content-box;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0px;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        min-height: 48px;
        ${isDisabled && 'opacity: 0.7;'}
        :hover {
          background-color: ${paymentMethods?.googlePay?.style === 'light'
            ? '#cfd4d8'
            : '#3c4043'};
        }
      `}
    />
  );
};

export default GooglePayButton;
