import { Global, css } from '@emotion/react';
import React from 'react';
import Modal from 'react-modal';

import { useTheme } from '@/ui/theme';

interface SideNavigationDrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
}

export const SideNavigationDrawer = (props: SideNavigationDrawerProps) => {
  // Set the app root so react-modal can manage aria context for accessibility
  Modal.setAppElement('#__next');

  const { colors } = useTheme();

  return (
    <>
      <Global
        styles={css`
          .SideDrawer__Body--open {
            overflow-y: hidden;
          }
          .SideDrawer.ReactModal__Content {
            transform: translateX(-100vw);
            transition: all 0.4s ease-in-out;
          }
          .SideDrawer.ReactModal__Content--after-open {
            left: 0;
            transform: translateX(0);
          }
          .SideDrawer.ReactModal__Content--before-close {
            transform: translateX(-100%);
          }
          .SideDrawer.ReactModal__Content {
            overflow-y: auto;
          }
        `}
      />
      <Modal
        isOpen={props.isOpen}
        bodyOpenClassName="SideDrawer__Body--open"
        onRequestClose={props.onRequestClose}
        className="SideDrawer"
        closeTimeoutMS={400}
        style={{
          content: {
            width: '100vw',
            position: 'absolute',
            // top: 87,
            top: 0,
            bottom: 0,
            borderRadius: 0,
            border: 'none',
            background: colors.white,
            left: 0,
            right: 0,
            outline: 'none',
            paddingTop: '60px',
          },
          overlay: {
            width: '100vw',
            background: 'rgba(0, 0, 0, 0)',
            zIndex: 1199,
          },
        }}
      >
        {props.children}
      </Modal>
    </>
  );
};

export default SideNavigationDrawer;
