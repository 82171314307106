import { css } from '@emotion/react';
import React from 'react';

import { useHotel } from '@/hotel';
import { renderCancellationPolicyBody } from '@/hotel/helpers/render-cancellation-policy-body';
import { useTranslation } from '@/i18n';
import { AnchorButton } from '@/ui/controls';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import { CancellationPolicyModal } from './CancellationPolicyModal.component';

interface PaymentAndCancellationPolicyAnchorProps {
  textAlign?: 'center' | 'left';
  hotelReferenceId: string;
}

export const PaymentAndCancellationPolicyAnchor = (
  props: PaymentAndCancellationPolicyAnchorProps
) => {
  const hotel = useHotel(props.hotelReferenceId);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation('cancellationPolicy');
  const body = hotel?.paymentCancellationPolicy.description ?? '';
  const { componentProperties } = useTheme();

  return (
    <React.Fragment>
      <BodySmall color="textSecondary" textAlign={props.textAlign}>
        <AnchorButton
          onClick={() => setIsModalOpen(true)}
          css={css(
            componentProperties.search.results.sidebarContainer
              ?.cancellationPolicy?.mobile
          )}
        >
          {t('title')}
        </AnchorButton>
      </BodySmall>
      <CancellationPolicyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        content={renderCancellationPolicyBody(body, {
          infoEmailAddress: hotel?.contact.emails.info,
        })}
      />
    </React.Fragment>
  );
};

export default PaymentAndCancellationPolicyAnchor;
