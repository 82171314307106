import mapValues from 'lodash.mapvalues';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { RefinementCtx, z } from 'zod';

export function SPR<Input, Output>(
  result: z.SafeParseReturnType<Input, Output>
): {
  success: typeof result['success'];
  data: z.SafeParseSuccess<Output>['data'] | undefined;
  error: z.SafeParseError<Input>['error'] | undefined;
} {
  return result.success
    ? { ...result, error: undefined }
    : { ...result, data: undefined };
}

type PartialSafeParseReturn<Schema> =
  | {
      successType: 'full';
      validData: Schema;
      invalidData: Partial<Schema>;
    }
  | {
      successType: 'partial';
      validData: Partial<Schema>;
      invalidData: Partial<Schema>;
    };
/**
 * This function allows you to run the safeParse method on a zod object schema, whilst returning valid and invalid data even if safeParse fails
 * @param schema
 * @param query
 * @param refinement
 */
export const partialSafeParse = <Schema extends z.AnyZodObject>(
  schema: Schema,
  query: unknown,
  refinement?: (arg: z.infer<Schema>, ctx: RefinementCtx) => void
): ReturnType<typeof SPR> & PartialSafeParseReturn<z.infer<Schema>> => {
  const schemaToParse = refinement ? schema.superRefine(refinement) : schema;
  const result = SPR(schemaToParse.safeParse(query));

  if (result.success) {
    return {
      ...result,
      successType: 'full',
      validData: result.data as z.infer<Schema>,
      invalidData: {},
    };
  }

  const { fieldErrors } = result.error?.flatten() ?? {};

  const inputObj = query as z.infer<Schema>;

  const keysWithInvalidData = Object.keys(fieldErrors ?? {});
  const invalidData = pick(inputObj, keysWithInvalidData) as Partial<
    z.infer<Schema>
  >;
  const validInput = omit(inputObj, keysWithInvalidData) as Partial<
    z.infer<Schema>
  >;

  const validData = schema
    .omit(mapValues(fieldErrors, () => true))
    .parse(validInput) as Partial<z.infer<Schema>>;

  return {
    ...result,
    successType: 'partial',
    validData,
    invalidData,
  };
};
