import { css } from '@emotion/react';
import React from 'react';
// import Swiper core and required modules
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import leftButtonImage from '@/ui/resources/svg/button-carousel-left.svg';
import rightButtonImage from '@/ui/resources/svg/button-carousel-right.svg';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

type ButtonDirection = 'left' | 'right';

const getButtonImage = (direction: ButtonDirection) => {
  switch (direction) {
    case 'left':
      return leftButtonImage.src;
    case 'right':
      return rightButtonImage.src;
  }
};

interface ContentSliderProps {
  children: React.ReactNode[];
  // width?: number;
  // height?: number;
  // widthString?: string;
}

export const ContentSlider = (props: ContentSliderProps) => {
  const {
    colors,
    componentProperties: { imageSlider },
  } = useTheme();

  return (
    <>
      <div
        css={css`
          // Make this wrapper component the anchor by position: relative, so that we can pop out elements from inside the swiper-container (which is overflow: hidden)
          position: relative;
          margin-bottom: 30px;

          .swiper-container {
            position: static;
          }

          .swiper-pagination {
            bottom: -36px;
            text-align: left;
            span {
              ${imageSlider?.paginator?.color &&
              `color: ${imageSlider?.paginator?.color};`}
            }
          }

          .swiper-button-next,
          .swiper-button-prev {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            border: 1px solid ${colors.secondary100};
            background-color: #fff;
            opacity: 0.5;
            will-change: opacity;
            transition: opacity 0.3s ease, box-shadow 0.3s ease;
            box-shadow: 0;

            &:focus,
            &:hover {
              opacity: 1;
              box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);

              &.swiper-button-disabled {
                opacity: 0;
              }
            }

            &:after {
              content: '';
              background: url(${getButtonImage('left')}) center;
              width: 20px;
              height: 20px;
              position: relative;
            }
          }

          .swiper-button-next:after {
            background: url(${getButtonImage('right')}) center;
            left: 1px;
          }
          .swiper-button-prev:after {
            background: url(${getButtonImage('left')}) center;
            left: -1px;
          }

          .swiper-button-disabled {
            opacity: 0;
          }

          .swiper-pagination-fraction {
            display: flex;
            justify-content: flex-start;

            /**
             * Offset this for mobile search view
             */
            @media all and (max-width: 639px) {
              padding-left: 28px;
            }

            .swiper-pagination-line {
              width: 16px;
              height: 1px;
              background: ${imageSlider?.paginator?.color ??
              colors.textSecondary};
              display: inline-block;
              margin: 12px 10px 0 10px;
            }

            .swiper-pagination-total {
              color: ${imageSlider?.paginator?.color ?? colors.textSecondary};
            }
          }

          .swiper-notification {
            position: absolute;
            left: -99999px;
          }
        `}
      >
        <BodySmall>
          <Swiper
            pagination={{
              clickable: true,
              type: 'fraction',
              renderFraction: (currentClass, totalClass) =>
                `<span class="${currentClass}"></span><span class="swiper-pagination-line"></span><span class="${totalClass}"></span>`,
            }}
            navigation
          >
            {props.children.map((item, key) => (
              <SwiperSlide key={key}>{item}</SwiperSlide>
            ))}
          </Swiper>
        </BodySmall>
      </div>
      <Spacer s="l" />
    </>
  );
};

export default ContentSlider;
