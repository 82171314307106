import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React from 'react';

import { useSpaceMatchingKey, useTheme } from '../theme';

interface CardWithHeadingProps {
  children: React.ReactNode;
  heading?: React.ReactNode;
  layoutId?: string;
  rounded?: boolean;
  shadow?: boolean;
  className?: string;
}

export const CardWithHeading: React.FC<CardWithHeadingProps> = (props) => {
  const verticalPadding = useSpaceMatchingKey('xs');
  const horizontalPadding = useSpaceMatchingKey('m');

  const {
    colors,
    keylines,
    componentProperties: { sidebarCards },
  } = useTheme();

  return (
    <motion.div
      className={props.className}
      css={css({
        background: colors.white,
        borderRadius: sidebarCards.secondary.borderRadius,
        overflow: 'hidden',
        boxShadow: props.shadow
          ? '0px 10px 40px rgba(0, 0, 0, 0.1)'
          : undefined,
        border: keylines?.border,
      })}
      layoutId={props.layoutId}
      layout
    >
      {props.heading}
      <div
        css={css`
          padding: ${verticalPadding} ${horizontalPadding} ${horizontalPadding};
        `}
      >
        {props.children}
      </div>
    </motion.div>
  );
};
