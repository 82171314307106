import { getErrorDescriptionKey, getErrorTitleKey } from '.';
import { APIError } from './api-error.interface';
import { ERROR_STRINGS } from './resources/error-strings.const';

export class DomainError extends Error {
  code: keyof (typeof ERROR_STRINGS)['errors'];
  description?: string;

  constructor(apiError: APIError) {
    super();

    this.code = apiError.code;
    this.description = apiError.description;
  }

  get messageTranslationKey() {
    return getErrorDescriptionKey(this.code as any);
  }

  get titleTranslationKey() {
    return getErrorTitleKey(this.code as any);
  }

  /**
   * Fallback URL to help users complete the action they requested. If this is present, consumers should redirect clients immediately to this URL.
   */
  get redirectUrl() {
    if (this.code !== 'REDIRECT_01') {
      return undefined;
    }

    // In cases where the code matches REDIRECT_01, the description will contain the destination URL.
    return this.description;
  }

  get message() {
    const descriptionWithColon =
      this.description !== undefined ? `: ${this.description}` : '';
    return `${this.code}${descriptionWithColon}`;
  }

  get isRetryable() {
    return ['GATEWAY_03', 'LOCAL_NETWORK_ERROR_01', 'OPERA_01'].includes(
      this.code
    );
  }

  get isServiceUnavailable() {
    return ['GATEWAY_03', 'OPERA_01', 'UNKNOWN_ERROR'].includes(this.code);
  }
}
