import React from 'react';

import { Spacer } from '@/ui/spacing';
import { Heading } from '@/ui/typography';

interface BookingWizardStepTitleProps {
  children: string;
}

export const BookingWizardStepTitle = (props: BookingWizardStepTitleProps) => {
  return (
    <>
      <Spacer s="xl" />
      <Heading as="h1" style="heading1">
        {props.children}
      </Heading>
      <Spacer s="xl" />
    </>
  );
};

export default BookingWizardStepTitle;
