import React from 'react';
import { animated, useSpring } from 'react-spring';

import illustrationSvg from '../../resources/svg/dancing.svg';

export const Illustration = () => {
  const [isLoaded] = React.useState(true);

  const styles = useSpring({
    // from: { opacity: 0, transform: 'scale(0.97)' },
    opacity: isLoaded ? 1 : 0,
    transform: 'scale(1.0)',
  });

  return (
    <animated.img
      src={illustrationSvg.src}
      width={138}
      height={184}
      style={styles}
      alt="Dancing illustration"
    />
  );
};

export default Illustration;
