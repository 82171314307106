import { css } from '@emotion/react';
import React from 'react';

import { Box } from '@/box';

import { useTheme } from '../theme';

interface BannerProps {
  imageUrl?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  alt: string;
}

export const Banner = (props: BannerProps) => {
  const { colors, componentProperties } = useTheme();

  const backgroundColor =
    props.backgroundColor ??
    colors[componentProperties.banners.backgroundColor];
  const color = colors[componentProperties.banners.color];

  return (
    <div
      css={css`
        background: ${backgroundColor};
        color: ${color};
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;
      `}
    >
      {props.imageUrl && (
        <Box marginLeft={20} width={[92]} display={['none', 'block']}>
          <img src={props.imageUrl} width="100%" alt={props.alt} />
        </Box>
      )}

      <Box
        marginLeft={props.imageUrl ? [32, 72] : 20}
        maxWidth={props.imageUrl ? 450 : 520}
        marginRight={20}
        marginTop={20}
        marginBottom={20}
        flex={1}
      >
        {props.children}
      </Box>
    </div>
  );
};
