import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { createArrayOfLength } from '@/common/utils/array';
import { useSpaceMatchingKey, useTheme } from '@/ui/theme';

interface HotelRoomSpecsProps {
  children: React.ReactNode[];
  className?: string;
}

export const HotelRoomSpecs = observer((props: HotelRoomSpecsProps) => {
  const {
    componentProperties: { search },
  } = useTheme();
  const xxsSpace = useSpaceMatchingKey('xxs');

  const { roomSpecColumns } = search.results;

  return (
    <div
      className={props.className}
      css={css`
        display: grid;
        grid-template-columns: ${createArrayOfLength(roomSpecColumns)
          .map(() => `${100 / roomSpecColumns}%`)
          .join(' ')};
        column-gap: ${xxsSpace};
        row-gap: ${xxsSpace};
      `}
    >
      {props.children}
    </div>
  );
});

export default HotelRoomSpecs;
