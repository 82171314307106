import { Global, css } from '@emotion/react';
import React from 'react';
import ReactModal from 'react-modal';

export interface BaseModalProps
  extends Pick<ReactModal.Props, 'onRequestClose' | 'isOpen'> {
  centered?: boolean;
  children: React.ReactNode;
}

export const BaseModal: React.FC<BaseModalProps> = (props) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          .BasicModal__Body--open {
            overflow-y: hidden;
          }
          .BasicModal.ReactModal__Content {
            transform: scale(0.9);
            transition: all 0.2s ease;
            opacity: 0;
          }
          .BasicModal.ReactModal__Content--after-open {
            left: 0;
            transform: scale(1);
            opacity: 1;
          }
          .BasicModal.ReactModal__Content--before-close {
            transform: scale(0.9);
            opacity: 0;
          }
          .BasicModal.ReactModal__Content {
            overflow-y: auto;

            @media all and (max-width: 720px) {
              padding: 32px !important;
            }
          }
          .OverlayBasicModal {
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1200;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow-y: auto;
            /* position:  */
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
          }

          .OverlayBasicModal.ReactModal__Overlay {
            transition: all 0.2s ease;
            opacity: 0;
          }
          .OverlayBasicModal.ReactModal__Overlay--after-open {
            opacity: 1;
          }
          .OverlayBasicModal.ReactModal__Overlay--before-close {
            opacity: 0;
          }
        `}
      />
      <ReactModal
        bodyOpenClassName="BasicModal__Body--open"
        className="BasicModal"
        overlayClassName="OverlayBasicModal"
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
            zIndex: 1200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: props.centered ? 'center' : 'flex-start',
            overflowY: 'auto',
          },
          content: {
            borderRadius: 0,
            border: 'none',
            // padding: props.padding || 64,
            height: 'auto',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: 'auto',
            // marginTop: '5vh',
            // maxWidth: props.contentMaxWidth || 'inherit',
            // marginBottom: '5vh',

            marginLeft: '24px',
            marginRight: '24px',
            // backgroundColor: props.backgroundColor || 'white',
            outline: 'none',
          },
        }}
      >
        {props.children}
      </ReactModal>
    </React.Fragment>
  );
};
