import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';
import enUS from './locale/en-US.json';

export const twentyOneCBrandConfig: BrandConfigurationFactory = () => ({
  chainCode: '21c',
  themeKey: '21c',
  name: '21c',
  defaultHotelCode: '21c.museumhotel-bentonville',
  hotels: [
    {
      referenceId: '21c.museumhotel-bentonville',
      code: '21c.museumhotel-bentonville',
      name: '21c Museum Hotel Bentonville',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-chicago',
      code: '21c.museumhotel-chicago',
      name: '21c Museum Hotel Chicago',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-cincinnati',
      code: '21c.museumhotel-cincinnati',
      name: '21c Museum Hotel Cincinnati',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-durham',
      code: '21c.museumhotel-durham',
      name: '21c Museum Hotel Durham',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-kansascity',
      code: '21c.museumhotel-kansascity',
      name: '21c Museum Hotel Kansas City',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-lexington',
      code: '21c.museumhotel-lexington',
      name: '21c Museum Hotel Lexington',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-louisville',
      code: '21c.museumhotel-louisville',
      name: '21c Museum Hotel Louisville',
      dialect: 'ohip',
    },
    {
      referenceId: '21c.museumhotel-stlouis',
      code: '21c.museumhotel-stlouis',
      name: '21c Museum Hotel St. Louis',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://21cmuseumhotels.com',
  domains: [
    'booking.21c.local',
    '21c.dev.env.ennismore.com',
    '21c.staging.env.ennismore.com',
    'booking.21cmuseumhotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US'],
  },

  maxRoomCountPerBooking: 7,

  search: {
    errorResolutionType: 'contact-button',
  },

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://www.21cmuseumhotels.com/privacy/',
        cookiePolicy: 'https://www.21cmuseumhotels.com/privacy/',
        termsOfUse: 'https://www.21cmuseumhotels.com/terms/',
        contact: 'https://www.21cmuseumhotels.com/company/contact/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: true,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
    'en-US': enUS,
  },

  analytics: {
    gtm: {
      containerId: 'GTM-WSWS23',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '7cc5ddd5-cc36-4433-afc1-86795982d007',
    },
  },
});
