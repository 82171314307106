import { css } from '@emotion/react';
import React from 'react';

import { useSpaceMatchingKey, useTheme } from '@/ui/theme';

interface SidebarSectionProps {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

export const SidebarSection = (props: SidebarSectionProps) => {
  const { colors, componentProperties, keylines } = useTheme();
  const secondaryCardStyle = componentProperties.sidebarCards.secondary;
  const paddingSpace = useSpaceMatchingKey(
    componentProperties.sidebarCards.secondary.padding
  );

  // This is a bit of a mess - but hopefully new format a little clearer
  // Let's cleanup when we re-work this.
  // First, take the border from the keylines config:
  let border = keylines?.border;
  // If that's missing, use a standard border with the border color
  if (!border && secondaryCardStyle.borderColor) {
    border = `1px solid ${colors[secondaryCardStyle.borderColor]}`;
  }
  // Lastly, hide the border if the "hide" flag is enabled
  if (secondaryCardStyle.hideBorder) {
    border = 'none';
  }

  return (
    <section
      className={props.className}
      css={css`
        padding: ${paddingSpace};

        ${secondaryCardStyle.paddingTop &&
        `padding-top: ${secondaryCardStyle.paddingTop};`}
        ${secondaryCardStyle.paddingBottom &&
        `padding-bottom: ${secondaryCardStyle.paddingBottom};`}
        background: ${colors[secondaryCardStyle.backgroundColor]};
        border: ${border};
        ${secondaryCardStyle.borderTop &&
        `border-top: ${secondaryCardStyle.borderTop};`}
        ${secondaryCardStyle.borderBottom &&
        `border-bottom: ${secondaryCardStyle.borderBottom};`}
        ${secondaryCardStyle.borderRight &&
        `border-right: ${secondaryCardStyle.borderRight};`}
        ${secondaryCardStyle.borderLeft &&
        `border-left: ${secondaryCardStyle.borderLeft};`}
        border-radius: ${secondaryCardStyle.borderRadius};
      `}
      data-test={props.testId}
    >
      {props.children}
    </section>
  );
};

export default SidebarSection;
