import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useHotel } from '@/hotel';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { useTranslation } from '@/i18n';
import { Checkbox } from '@/ui/form';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodyCopy } from '@/ui/typography';

import type { SearchFormFields } from '../validators/search-form.validator';

export const AccessibleRoomsFilterCheckboxInput = ({
  index,
}: {
  index: number;
}) => {
  const { t } = useTranslation('search');
  const { forms } = useTheme();

  const { register, watch, setValue } =
    useFormContext<Pick<SearchFormFields, 'roomOccupancy' | 'hotelCode'>>();

  const [roomOccupancy, hotelCode] = watch(['roomOccupancy', 'hotelCode']);

  const hotelReferenceId = resolveHotelReferenceIdFromHotelCode(hotelCode);

  const hotel = useHotel(hotelReferenceId);

  // Reset the checkbox if the hotel doesn't have accessible rooms,
  // so that the value won't get added to the URL.
  useEffect(() => {
    if (!hotel?.operational.hasAccessibleRooms) {
      setValue(`roomOccupancy.${index}.accessibleRooms`, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel?.operational.hasAccessibleRooms]);

  // A workaround to get arround the bug where RFH is setting the value as plain string (vs [string]) when checked
  // and false when unchecked (vs []) for dynamically added checkboxes.
  useEffect(() => {
    if (typeof roomOccupancy[index].accessibleRooms === 'string') {
      setValue(`roomOccupancy.${index}.accessibleRooms`, [
        // @ts-expect-error this a string, not an array of strings
        roomOccupancy[index].accessibleRooms,
      ]);
    } else if (typeof roomOccupancy[index].accessibleRooms === 'boolean') {
      setValue(`roomOccupancy.${index}.accessibleRooms`, []);
    }
  }, [roomOccupancy[index].accessibleRooms]);

  if (!hotel) return null;

  return (
    <>
      <Spacer s="xxs" />
      {hotel?.operational.hasAccessibleRooms ? (
        <Checkbox
          label={t('form.accessibleRoomsCheckboxLabel')}
          isChecked={roomOccupancy[index].accessibleRooms.length > 0}
          {...register(`roomOccupancy.${index}.accessibleRooms`)}
        />
      ) : (
        <BodyCopy css={forms.label}>
          {t('form.noAccessibleRoomsMessage')}
        </BodyCopy>
      )}
      <Spacer s="s" />
    </>
  );
};
