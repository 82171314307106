import { css } from '@emotion/react';
import React from 'react';

import { AvailableSpaceKeyType, useSpaceMatchingKey } from '../theme';

interface SpacerProps {
  s: AvailableSpaceKeyType;
}

export const Spacer = (props: SpacerProps) => {
  return <div css={css({ height: useSpaceMatchingKey(props.s) })} />;
};

export default Spacer;
