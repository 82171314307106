import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '../theme';
import { ButtonProps } from './Button.component';

export interface ButtonWithIconProps extends Omit<ButtonProps, 'variant'> {
  icon?: React.ReactNode;
  iconSrc?: string;
  // We're passing this in until we have our internationalisation framework worked out.
  iconAlt: string;
}

export const ButtonWithIcon = ({
  children,
  icon,
  iconSrc,
  iconAlt,
  isDisabled,
  onClick,
  ...rest
}: ButtonWithIconProps) => {
  const iconElement =
    icon ||
    (iconSrc ? (
      <img src={iconSrc} sx={{ marginLeft: 12 }} alt={iconAlt} />
    ) : (
      ''
    ));
  const { colors, fonts } = useTheme();
  return (
    <button
      css={css({
        appearance: 'none',
        textDecoration: 'none',
        fontWeight: 'bold',
        m: 0,
        px: '16px',
        py: '12px',
        border: 0,
        borderRadius: 4,
        transition: 'all ease .2s',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',

        ':focus': {
          outline: `${colors.textSecondary} auto 1px`,
          outlineColor: colors.textSecondary,
        },

        color: colors.textPrimary,
        backgroundColor: 'transparent',
        paddingY: '16px',
        paddingX: '16px',
        borderColor: colors.textPrimary,
        borderWidth: 1,
        borderStyle: 'solid',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: fonts.body,

        '&:hover:not(:disabled)': {
          borderColor: colors.textSecondary,
          color: colors.textSecondary,
        },
        '&:disabled': {
          opacity: 0.4,
          cursor: 'not-allowed',
        },
      })}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
      {...rest}
    >
      {children}
      {iconElement}
    </button>
  );
};

export default ButtonWithIcon;
