import { css } from '@emotion/react';
import React from 'react';

import { Box, Flex } from '@/box';
import { useKeyPress } from '@/common/hooks';
import Card from '@/core/components/layout/Card.component';
import Overlay from '@/core/components/layout/Overlay.component';
import { useTranslation } from '@/i18n';
import { AnchorButton } from '@/ui/controls';
import { useTheme } from '@/ui/theme';
import { BodyCopy } from '@/ui/typography';

import { SearchFormCard } from './SearchFormCard.component';

interface CollapsibleSearchFormProps {
  searchForm: React.ReactNode;
  searchSummary: React.ReactNode;
  onActionButtonClick: () => void;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const CollapsibleSearchForm = (props: CollapsibleSearchFormProps) => {
  const { t } = useTranslation(['common', 'search']);
  const {
    componentProperties: { search },
  } = useTheme();

  const styles = search.collapsibleForm;

  useKeyPress((event) => event.key === 'Escape' && props.onRequestClose());

  return (
    <div
      css={css`
        width: 100%;
        position: relative;
        z-index: 300;
      `}
      aria-expanded={props.isOpen}
    >
      <Overlay isVisible={props.isOpen} onClick={props.onRequestClose} />

      <Card
        testId="collapsible-search-form"
        css={{
          position: 'relative',
          zIndex: 300,
          ...search.collapsibleForm?.searchSummary,
          span: {
            color: search.collapsibleForm?.searchSummary?.color,
            textTransform: search.collapsibleForm?.searchSummary?.textTransform,
            fontFamily: search.collapsibleForm?.searchSummary?.fontFamily,
          },
          button: styles?.searchSummary?.modifySearchButton,
          ...(props.isOpen ? styles?.searchSummary?.expanded : {}),
        }}
      >
        <Flex>
          <Box flex={1}>{props.searchSummary}</Box>
          <Box>
            <BodyCopy>
              <AnchorButton onClick={props.onActionButtonClick}>
                {props.isOpen
                  ? t('common:controls.close')
                  : t('search:modifySearchButtonLabel')}
              </AnchorButton>
            </BodyCopy>
          </Box>
        </Flex>
      </Card>
      <SearchFormCard
        isOpen={props.isOpen}
        css={css({
          ...styles?.searchForm,
          form: {
            backgroundColor: styles?.searchForm?.backgroundColor,
            'span a': { color: styles?.searchForm?.color },
          },
        })}
      >
        {props.searchForm}
      </SearchFormCard>
    </div>
  );
};
