import { css } from '@emotion/react';
import React from 'react';

import { useTranslation } from '@/i18n';
import { AnchorButton } from '@/ui/controls';
import { useTheme } from '@/ui/theme';

interface MobileModifySearchFormButtonProps {
  onClick: () => void;
}

export const MobileModifySearchFormButton = (
  props: MobileModifySearchFormButtonProps
) => {
  const { t } = useTranslation('search');
  const {
    componentProperties: { search },
  } = useTheme();
  return (
    <AnchorButton
      css={css({
        color: search.collapsibleForm?.searchSummary?.color,
        ...search.collapsibleForm?.searchSummary?.modifySearchButton,
      })}
      onClick={props.onClick}
    >
      {t('modifySearchButtonLabel')}
    </AnchorButton>
  );
};
