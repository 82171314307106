import { css } from '@emotion/react';
import React from 'react';
import { animated, useSpring } from 'react-spring';

import { LoadingIndicator } from '@/ui/controls';

export const LargeLoadingIndicator = ({
  background,
  maxHeight,
}: {
  background?: string;
  maxHeight?: number;
}) => {
  const styles = useSpring({
    opacity: 1,
    transform: 'scale(1.0)',
    from: { opacity: 0, transform: 'scale(0.96)' },
    delay: 0,
  });

  return (
    <animated.div
      css={css`
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${background || 'transparent'};
        ${maxHeight ? `max-height: ${maxHeight}px` : ''};
      `}
      style={styles}
    >
      <LoadingIndicator style="dark" />
    </animated.div>
  );
};

export default LargeLoadingIndicator;
