import { css } from '@emotion/react';
import React, { useId, useState } from 'react';

import InfoButton from '@/hox-core/components/controls/InfoButton.component';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

interface DataTableRowProps {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  helpText?: React.ReactNode;
  hideColon?: boolean;
  testId?: string;
  valueFontWeight?: React.CSSProperties['fontWeight'];
}

/**
 * Used to render a key-value pair, usually as a table row.
 */
export const DataTableRow = (props: DataTableRowProps) => {
  const [isHelpTextVisible, setIsHelpTextVisible] = useState(false);
  const {
    componentProperties: { staySummary },
  } = useTheme();
  const id = useId();
  const testId = `data-row--${props.testId}`;

  return (
    <React.Fragment>
      <div
        data-test={testId}
        className="field-section"
        role="row"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          line-height: 24px;
          padding: 2px 0;

          &:not(:last-child) {
            /* margin-bottom: 8px; */
          }

          .label {
            align-self: flex-start;

            ${staySummary?.color && `color: ${staySummary.color};`}
            span {
              ${staySummary?.color && `color: ${staySummary.color};`}
            }

            strong {
              font-weight: 400;
            }
          }

          .value {
            text-align: right;
            ${props.valueFontWeight && `font-weight: ${props.valueFontWeight};`}
          }
        `}
      >
        <div className="label" id={id} data-test="data-row-label">
          <BodySmall
            inline
            css={css({ opacity: 0.56, ...staySummary?.dataRow?.label })}
          >
            {props.label}
            {props.hideColon ? '' : ':'}
          </BodySmall>
          {props.helpText && (
            <span style={{ marginLeft: '8px' }} data-test="help-text">
              <InfoButton
                onClick={() => setIsHelpTextVisible((state) => !state)}
              />
            </span>
          )}
        </div>
        <div
          className="value"
          aria-labelledby={id}
          role="gridcell"
          data-test="data-row-value"
        >
          <BodySmall css={css(staySummary?.dataRow?.value)}>
            {props.value}
          </BodySmall>
        </div>
      </div>
      {props.helpText && isHelpTextVisible && (
        <>
          <Spacer s="xxs" />
          <BodySmall>{props.helpText}</BodySmall>
          <Spacer s="xxs" />
        </>
      )}
    </React.Fragment>
  );
};

export default DataTableRow;
