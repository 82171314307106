import { css } from '@emotion/react';
import React from 'react';
import { animated, useTransition } from 'react-spring';

interface OverlayProps {
  isVisible: boolean;
  onClick?: () => void;
}

export const Overlay = ({ isVisible, onClick }: OverlayProps) => {
  const transitions = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  });

  const cssStyle = css`
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  `;

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              css={cssStyle}
              key={key}
              style={props}
              onClick={onClick}
            />
          )
      )}
    </>
  );
};

export default Overlay;
