import React from 'react';

import { getEnvironmentKey } from '@/env';

/**
 * Includes the OneTrust script to be placed within the <Head />.
 * This should be rendered at the _app.tsx level as it requires access to the brand config,
 * which is then used to supply this component with the OneTrust ID.
 */
export const OneTrustScript = ({
  id: oneTrustID,
}: {
  id: string | undefined;
}) => {
  if (!oneTrustID) return null;

  const environment = getEnvironmentKey();

  const testEnvironment = environment !== 'live';

  return (
    <>
      {/* Asynchronously loaded OneTrust Cookies Consent Notice */}
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script={`${oneTrustID}${testEnvironment ? '-test' : ''}`}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: `function OptanonWrapper(){}` }}
      />
    </>
  );
};

export const OneTrustCookieSettingsButton = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <a
    href="#cookie-banner"
    onClick={() => {
      // TODO: extend window to include Optaonon
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: Optanon actually gets defined
      window.Optanon?.ToggleInfoDisplay();
    }}
  >
    {children}
  </a>
);
