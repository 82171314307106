import { useAppEvents } from '@/events';
import { IHotelModelInstance } from '@/hotel';
import { useEffect } from 'react';
import { AvailabilityEvents } from '../events';
import { IRoomAvailabilityInstance } from '../models';

/**
 * Emits an event representing the impression of hotel + availability.
 * This will be picked up and emitted to the dataLayer.
 */
export const useRoomImpressionTracking = ({
  hotel,
  availability,
}: {
  hotel: IHotelModelInstance | undefined;
  availability: IRoomAvailabilityInstance[];
}) => {
  const events = useAppEvents<AvailabilityEvents>();

  useEffect(() => {
    if (!hotel) return;

    events.emit('roomsImpression', {
      hotel,
      availability,
    });
  }, [events, JSON.stringify(hotel), JSON.stringify(availability)]);
};
