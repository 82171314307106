import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '../theme';

type ApplePayButtonType = 'book' | 'pay' | 'continue';

interface ApplePayButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  type?: ApplePayButtonType;
}

export const ApplePayButton = (props: ApplePayButtonProps) => {
  const {
    buttons,
    componentProperties: { paymentMethods },
  } = useTheme();
  return (
    <button
      css={css`
        /* background-color: transparent; */
        /* background-image: -webkit-named-image(apple-pay-logo-black); */

        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: ${props.type ?? 'pay'};
        -apple-pay-button-style: ${paymentMethods?.applePay?.style === 'light'
          ? 'white'
          : 'black'};
        border: 1px solid ${buttons.primary.backgroundColor};
        background-size: 100% 100%;
        background-origin: content-box;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        min-height: 48px;
        padding: 20px 0;
        border-radius: 4px;
        cursor: pointer;
        ${props.isDisabled && 'opacity: 0.7;'}
        touch-action: manipulation;
      `}
      onClick={props.onClick}
      disabled={props.isDisabled}
      aria-disabled={props.isDisabled}
      aria-label="Apple Pay"
      data-testid={`apple-pay-button-${props.type}`}
    ></button>
  );
};

export default ApplePayButton;
