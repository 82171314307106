import { css } from '@emotion/react';
import React from 'react';

import RoomTotal from '@/hotel/components/room/RoomTotal.component';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall, Heading } from '@/ui/typography';

interface RoomRateCodeProps {
  name: string;
  description?: string | React.ReactNode;
  isAvailable: boolean;
  roomTotalElement: React.ReactElement<typeof RoomTotal> | undefined | false;
}

export const RoomRateCode = (props: RoomRateCodeProps) => {
  const {
    componentProperties: { bedroomCard },
  } = useTheme();
  return (
    <div>
      <Heading as="h6" style="heading6" css={css(bedroomCard?.roomRateName)}>
        {props.name}
      </Heading>
      {props.description && (
        <BodySmall color="secondary300">{props.description}</BodySmall>
      )}
      {props.isAvailable && (
        <>
          <Spacer s="xxs" />
          {props.roomTotalElement}
        </>
      )}
    </div>
  );
};

export default RoomRateCode;
