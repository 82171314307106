import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React from 'react';

import { Flex } from '@/box';

import { AccordionDropdownIndicator } from '../icons';
import { Spacer } from '../spacing';
import RadioButton, { RadioButtonProps } from './RadioButton.component';

interface RadioButtonAccordionProps extends RadioButtonProps {
  collapsibleContent: React.ReactNode;
}

export const RadioButtonAccordion = ({
  children,
  collapsibleContent,
  ...props
}: RadioButtonAccordionProps) => {
  return (
    <RadioButton isExpandable {...props}>
      <Flex>
        <div
          css={css`
            flex: 1;
          `}
        >
          <div>{children}</div>
          <motion.div
            layout
            animate={props.isChecked ? { height: 'auto' } : { height: 0 }}
            style={{ overflow: 'hidden' }}
            aria-hidden={!props.isChecked}
          >
            <Spacer s="xs" />
            {collapsibleContent}
          </motion.div>
        </div>
        <div>
          <AccordionDropdownIndicator
            isFlipped={!props.isChecked}
            width={14}
            height={7}
          />
        </div>
      </Flex>
    </RadioButton>
  );
};

export default RadioButtonAccordion;
