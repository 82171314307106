import { css } from '@emotion/react';
import React from 'react';

import { Box, Flex } from '@/box';
import { EnnismoreThemeIcons, useTheme } from '@/ui/theme';
import { useIcon } from '@/ui/theme/hooks/use-icon';
import { BodyCopy, Heading } from '@/ui/typography';

export const getAltForId = (id: string) => {
  switch (id) {
    case 'TRAIN':
      return 'Nearby station';
    case 'PLANE':
      return 'Nearby airport';
    default:
      return '';
  }
};

interface TransportOptionProps {
  mode: EnnismoreThemeIcons | string;
  title: string;
  description: string;
}

export const TransportOption = (props: TransportOptionProps) => {
  const {
    componentProperties: { transportOptions },
  } = useTheme();
  return (
    <Flex
      css={css`
        margin-bottom: 40px;
      `}
    >
      <div
        css={css`
          margin-right: 24px;

          @media all and (max-width: 700px) {
            margin-right: 8px;
          }
          img {
            ${transportOptions?.icon?.filter &&
            `filter: ${transportOptions?.icon?.filter};`}
          }
        `}
      >
        <img
          css={css`
            max-width: 24px;
            min-width: 12px;
          `}
          src={useIcon(props.mode)}
          alt={getAltForId(props.mode)}
        />
      </div>
      <Box flex={1}>
        <Heading as="h3" style="heading6">
          {props.title}
        </Heading>
        <BodyCopy>{props.description}</BodyCopy>
      </Box>
    </Flex>
  );
};

export default TransportOption;
