import React from 'react';

import { useActiveBrandConfig } from '@/brand';
import { useExperimentStatus } from '@/flags/use-experiments';
import { useHotel } from '@/hotel';
import { Markdown } from '@/markdown';
import { Banner } from '@/ui/layout';
import { useTheme } from '@/ui/theme';
import { BodyCopy } from '@/ui/typography';

import { AvailabilitySearchResultsBanner } from '../components';
import { DisloyaltyBanner } from '../components/banners/DisloyaltyBanner.component';
import { IAvailabilitySearchResultsModelInstance } from '../models/availability-search-results.model';

export interface AvailabilitySearchResultsBannersContainerInterface {
  results?: IAvailabilitySearchResultsModelInstance;
  hotelSlug: string;
}

export const AvailabilitySearchResultsBannersContainer: React.FC<
  AvailabilitySearchResultsBannersContainerInterface
> = (props) => {
  const { search } = useActiveBrandConfig();
  const { defaultBannerMessage } = search || {};
  const { componentProperties } = useTheme();
  const hotel = useHotel(props.hotelSlug);

  const showDisloyaltyBanner = useExperimentStatus('dis-loyalty-banner');

  if (search?.disableBanners) {
    return null;
  }

  if (!props.results || !hotel) {
    if (defaultBannerMessage) {
      return (
        <Banner alt="">
          <BodyCopy color={componentProperties.banners.color}>
            <Markdown source={defaultBannerMessage} />
          </BodyCopy>
        </Banner>
      );
    }
    return null;
  }

  return (
    <>
      {showDisloyaltyBanner && <DisloyaltyBanner hotelId={hotel.referenceId} />}
      {hotel.content.banners.map((banner) => (
        <AvailabilitySearchResultsBanner key={banner.id} bannerModel={banner} />
      ))}
    </>
  );
};
