import { css } from '@emotion/react';
import React from 'react';

import { AvailableSpaceKeyType, useSpaceMatchingKey } from '../theme';

type StackProps = {
  children: React.ReactNode;
  s?: AvailableSpaceKeyType;
  as?: React.ElementType;
  height?: React.CSSProperties['height'];
} & React.AriaAttributes;

export const Stack = ({
  children,
  s,
  as: Component = 'div',
  height,
  ...ariaAttributes
}: StackProps) => {
  const space = useSpaceMatchingKey(s || 's');

  return (
    <Component
      css={css({
        display: 'grid',
        rowGap: space,
        gridTemplateColumns: 'minmax(0, 1fr)',
        height,
      })}
      {...ariaAttributes}
    >
      {children}
    </Component>
  );
};

export default Stack;
