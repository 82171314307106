import { css } from '@emotion/react';
import { RadioGroupItem } from '@radix-ui/react-radio-group';
import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import React from 'react';

import { Box, Flex } from '@/box';
import RadioIcon from '@/ui/icons/RadioIcon.component';

import { useTheme } from '../theme';

export interface RadioButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  isExpandable?: boolean;
  testId: string;
  value: RadioGroupItemProps['value'];
}

export const RadioButton = ({
  onClick,
  children,
  isChecked,
  isDisabled,
  isExpandable,
  testId,
  value,
}: RadioButtonProps) => {
  const { colors, forms } = useTheme();

  return (
    <RadioGroupItem
      value={value}
      disabled={isDisabled}
      aria-expanded={isExpandable ? isChecked : undefined}
      data-testid={`${testId}-radio`}
      css={css`
        border: none;
        text-align: left;
        padding: 0;
        display: block;
        transition: all ease 0.1s;
        width: 100%;
        padding: 14px 8px 14px 8px;
        opacity: ${isDisabled ? 0.5 : 1};
        border-radius: 3px;

        ${forms.radioButton?.color && `color: ${forms.radioButton?.color};`}

        cursor: pointer;

        &:active {
          transition: none;
        }

        &:focus,
        &:active {
          position: relative;
          background-color: ${forms.radioButton?.selected?.backgroundColor ??
          colors.primary200};
          span,
          svg {
            ${forms.radioButton?.selected?.color &&
            `color: ${forms.radioButton?.selected?.color};`}
          }
        }

        img {
          margin-top: 2px;
        }
      `}
      onClick={isDisabled ? undefined : onClick}
    >
      <Flex>
        <Box marginRight="8px" width="16px">
          <div
            css={css`
              position: relative;
              top: 3px;
            `}
          >
            <RadioIcon isSelected={isChecked} />
          </div>
        </Box>
        <Box flex={1}>{children}</Box>
      </Flex>
    </RadioGroupItem>
  );
};

export default RadioButton;
