import getConfig from 'next/config';
import { z } from 'zod';

/**
 * Validation of public environment variables.
 */
const publicEnvironmentSchema = z.object({
  NEXT_PUBLIC_ENVIRONMENT: z
    .enum(['local', 'dev', 'preview', 'live', 'staging'])
    .default('local'),
  NEXT_PUBLIC_API_BASE_URL: z
    .string()
    .default('https://api.dev.env.ennismore.com/v2'),
  NEXT_PUBLIC_API_KEY: z.string().default(''),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_STRIPE_KEY_OVERRIDE: z.string().optional(),
  NEXT_PUBLIC_LOG_LEVEL: z.string().optional(),
  NEXT_PUBLIC_VERSION: z.string().optional(),

  /**
   * This is for internal use only. If this is set to 'true', we will warn the user that they're making a booking
   * against a live instance of Opera. We might choose to use this when pointing to live from a staging or preview
   * environment, which may result in human-error bookings.
   */
  NEXT_PUBLIC_LIVE_BOOKING_WARNING: z.string().optional(),
});

type BookingEngineEnvironment = z.infer<typeof publicEnvironmentSchema>;
// Ensure we get warned if we add an environment variable in the schema, but don't explicitly pass it to the parse call below.
type BookingEngineEnvironmentInput = {
  [key in keyof BookingEngineEnvironment]: any;
};

/**
 * Returns a validated object containing environment variables.
 */
export const getEnvironmentVars = () => {
  // Next.js explicitly replaces code references to environment variables, so we have to explitly pass them here.
  // See: https://nextjs.org/docs/basic-features/environment-variables#loading-environment-variables
  const source: BookingEngineEnvironmentInput = {
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    NEXT_PUBLIC_API_KEY: process.env.NEXT_PUBLIC_API_KEY,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
    NEXT_PUBLIC_STRIPE_KEY_OVERRIDE:
      process.env.NEXT_PUBLIC_STRIPE_KEY_OVERRIDE,
    NEXT_PUBLIC_LIVE_BOOKING_WARNING:
      process.env.NEXT_PUBLIC_LIVE_BOOKING_WARNING,
  };

  return publicEnvironmentSchema.parse({
    ...source,
    ...(getConfig()?.publicRuntimeConfig || {}),
  });
};

export const getEnvironmentKey = () =>
  getEnvironmentVars().NEXT_PUBLIC_ENVIRONMENT;

export const isEnvironment = (key: string) => getEnvironmentKey() === key;

export const isDevelopmentEnvironment = () => getEnvironmentKey() === 'dev';

export const isLocalDevelopmentEnvironment = () =>
  getEnvironmentKey() === 'local';

export const isProductionEnvironment = () => getEnvironmentKey() === 'live';

/**
 * Error reporting is enabled if there's a SENTRY_DSN set in the environment.
 */
export const getIsErrorReportingEnabled = () =>
  Boolean(getEnvironmentVars().NEXT_PUBLIC_SENTRY_DSN);
