import type { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';

import { OHIPBookingAPIBaseService } from '@/api/clients/ohip';
import { v1GetAvailabilityResponseSchema } from '@/api/clients/ohip/models';
import {
  AvailabilitySearchError,
  AvailabilitySearchResultsModel,
  IAvailabilitySearchResultsModelInstance,
  IAvailabilitySearchResultsModelSnapshot,
  IRoomAvailabilitySnapshot,
} from '@/availability/models';
import { IStayCostSnapshot } from '@/hotel';
import { getLanguageFromLocale } from '@/i18n/utils';

import type { AvailabilityServiceClient } from './common';

export class OHIPAvailabilityServiceClient
  extends OHIPBookingAPIBaseService
  implements AvailabilityServiceClient
{
  /**
   * @throws {AvailabilitySearchError}
   */
  async getAvailability(
    request: V1GetAvailabilityRequest & { locale: { language: string } }
  ): Promise<IAvailabilitySearchResultsModelInstance> {
    try {
      // Call the booking API
      const response = await this.client.bookingApiServiceGetAvailability(
        request.locale.language,
        request,
        this.options
      );

      // Validate that the availability response matches the spec.
      // This is enforced at runtime by Zod.
      const validatedResponse = v1GetAvailabilityResponseSchema.parse(
        response.data
      );

      const rooms = validatedResponse.rooms || [];

      // Transform the verified availability response into the legacy structure.
      // Will remove this as new components are built, keeping the data access stuff at one layer.
      const snapshot: IAvailabilitySearchResultsModelSnapshot = {
        rooms: rooms.map(
          (room): IRoomAvailabilitySnapshot => ({
            availability: room.rates.map(
              (rate): IStayCostSnapshot => ({
                rateCode: {
                  requestRateCode: rate.code,
                  description: rate.description,
                  name: rate.name,
                  amount: rate.cost.amountAfterTax,
                },
                totalRoomRate: rate.cost.amountSubtotalDisplay,
                averageNightlyRate: rate.averageNightlyAmountAfterTax,
                grandTotal: rate.cost.amountAfterTax,
                deposit: rate.deposit?.amount,
                taxesFees: rate.cost.tax?.detail,
                bookable: {
                  value: true, // Rooms will always be bookable if returned from OHIP
                },
                containsFees: rate.containsFees,
              })
            ),
            room: {
              code: room.room.code,
              name: room.room.name,
              extras: room.room.extras.map((extra) => ({
                id: extra.typeId,
                text: extra.text,
                title: extra.type, // `type` is the extras title
              })),
              images: room.room.images.map((image) => ({
                id: image.typeId,
                altText: image.altText,
                source: image.url,
              })),
              header: room.room.strapline,
              roomDescription: [
                {
                  description: room.room.description,
                  locale: request.locale.language || 'en',
                },
              ],
              filters: room.room.filters,
            },
          })
        ),
      };

      return AvailabilitySearchResultsModel.parse(snapshot);
    } catch (e) {
      throw new AvailabilitySearchError(this.parseError(e));
    }
  }
}
