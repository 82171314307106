import React, { useState } from 'react';

import BasicSidebarSection from '@/core/components/layout/BasicSidebarSection.component';
import { useHotel } from '@/hotel';
import { renderCancellationPolicyBody } from '@/hotel/helpers/render-cancellation-policy-body';
import { useTranslation } from '@/i18n';
import { AnchorButton } from '@/ui/controls';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import { CancellationPolicyModal } from './CancellationPolicyModal.component';

interface CancellationPolicyProps {
  children: React.ReactNode;
  hotelReferenceId: string;
}

export const CancellationPolicy = (props: CancellationPolicyProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('cancellationPolicy');
  const hotel = useHotel(props.hotelReferenceId);
  const {
    componentProperties: { search: searchStyles },
  } = useTheme();

  if (!hotel?.paymentCancellationPolicy) return null;

  return (
    <BasicSidebarSection title={t('title')}>
      <BodySmall color="textSecondary">
        {props.children}
        {/* Stop this tag being split over two lines which happens in the sidebar */}
        {hotel.paymentCancellationPolicy.description.length > 0 &&
          hotel.paymentCancellationPolicy.description !==
            hotel.paymentCancellationPolicy.summary && (
            <>
              {' '}
              <AnchorButton
                onClick={() => setIsModalOpen(true)}
                noWrap
                aria-label={t('readMoreAlt')}
                css={
                  searchStyles.results.sidebarContainer?.cancellationPolicy
                    ?.readMoreButton
                }
              >
                {t('readMoreLink')}
              </AnchorButton>
              <CancellationPolicyModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                content={renderCancellationPolicyBody(
                  hotel.paymentCancellationPolicy.description,
                  { infoEmailAddress: hotel.contact.emails.info }
                )}
              />
            </>
          )}
      </BodySmall>
    </BasicSidebarSection>
  );
};

export default CancellationPolicy;
