import { css } from '@emotion/react';
import React from 'react';

import PaymentAndCancellationPolicyAnchor from '@/booking/components/PaymentCancellationPolicyAnchor.component';
import { useHotelConfigBySlugOrFail } from '@/brand';
import ResponsiveConditional from '@/common/components/responsive/ResponsiveConditional.component';
import Card from '@/core/components/layout/Card.component';
import SideNavigationDrawer from '@/core/components/layout/SideNavigationDrawer.component';
import { MobileWrapperPadding } from '@/ui/layout';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import { HotelAvailabilitySearchSummaryContainer } from '.';
import { CollapsibleSearchForm, SearchSummaryMobile } from '../components';
import { IAvailabilitySearchQueryInstance } from '../models/availability-search-query.model';
import {
  HotelAvailabilitySearchFormContainer,
  OnSearchQueryChangeFn,
} from './HotelAvailabilitySearchForm.container';

export const HotelAvailabilitySearchCardContainer: React.FC<{
  query: IAvailabilitySearchQueryInstance;
  hotelReferenceId: string;
  isSearchFormOpen: boolean;
  onRequestCloseSearchForm: () => void;
  onRequestOpenSearchForm: () => void;
  onSearchQueryChange: OnSearchQueryChangeFn;
}> = ({
  query,
  hotelReferenceId,
  isSearchFormOpen,
  onRequestCloseSearchForm,
  onRequestOpenSearchForm,
  onSearchQueryChange,
}) => {
  const { name } = useHotelConfigBySlugOrFail(hotelReferenceId);
  const {
    componentProperties: { search },
  } = useTheme();

  return (
    <ResponsiveConditional
      desktop={
        <CollapsibleSearchForm
          searchForm={
            <HotelAvailabilitySearchFormContainer
              initialQuery={query}
              onBeforeSubmit={() => onRequestCloseSearchForm()}
              onSubmit={onSearchQueryChange}
            />
          }
          searchSummary={
            <HotelAvailabilitySearchSummaryContainer
              query={query}
              hotelReferenceId={hotelReferenceId}
            />
          }
          isOpen={isSearchFormOpen}
          onActionButtonClick={
            isSearchFormOpen
              ? onRequestCloseSearchForm
              : onRequestOpenSearchForm
          }
          onRequestClose={onRequestCloseSearchForm}
        />
      }
      mobile={
        <>
          <MobileWrapperPadding>
            <SearchSummaryMobile
              hotelName={name}
              from={query.from}
              to={query.to}
            />
            <Spacer s="s" />

            <PaymentAndCancellationPolicyAnchor
              hotelReferenceId={hotelReferenceId}
            />
          </MobileWrapperPadding>
          <SideNavigationDrawer
            isOpen={isSearchFormOpen}
            onRequestClose={onRequestCloseSearchForm}
          >
            <Card
              css={css({
                a: { color: search.collapsibleForm?.searchForm?.color },
              })}
            >
              <HotelAvailabilitySearchFormContainer
                // Quick fix for when the user scrolls underneath the mobile search drawer.
                onBeforeSubmit={() => {
                  onRequestCloseSearchForm();
                  window.scrollTo(0, 0);
                }}
                initialQuery={query}
                onSubmit={onSearchQueryChange}
              />
              <Spacer s="l" />
            </Card>
          </SideNavigationDrawer>
        </>
      }
    />
  );
};
