import { z } from 'zod';

import { dialectSchema } from '@/api';
import { v1RoomOccupancySchema } from '@/api/clients/ohip';
import { rolloutConfigurationSchema } from '@/availability-fallback/RolloutConfiguration.interface';
import { ImageModel } from '@/common/models/image.model';

import { ActivityModel } from './activity.model';
import { HotelBanner } from './hotel-banner.model';
import { HotelPerk } from './hotel-perk.model';
import { RestaurantModel } from './restaurant.model';

export type IHotelModel = typeof HotelConfigurationModel;
export type IHotelModelInstance = z.infer<IHotelModel>;
export type IHotelModelSnapshot = z.input<IHotelModel>;

export type HotelConfiguration = IHotelModelInstance;
export type HotelConfigurationInput = IHotelModelSnapshot;

const toLowerCase = (str: string) => str.toLowerCase();

// This exists in the i18n package, but we're copying here to decouple the two modules.
export const LocalisedStringSchema = z.object({
  en: z.string(),
  fr: z.string().optional(),
  it: z.string().optional(),
});

// Similarly, this exists in the finance package, but we're copying here to decouple the two modules.
export const CurrencyCodeSchema = z.enum(['USD', 'EUR', 'GBP']);
export type CurrencyCode = z.infer<typeof CurrencyCodeSchema>;

export const HotelConfigurationModel = z.object({
  /**
   * Hotel reference ID. eg. hoxton.lloyd-amsterdam
   */
  referenceId: z.string(),
  name: z.string(), // Required

  /**
   * eg. 'hoxton'
   */
  brandReferenceId: z.string(),

  /**
   * Which API dialect does this hotel use?
   */
  dialect: z.string().transform(toLowerCase).pipe(dialectSchema).default('ows'),

  /**
   * Now only used within the web app to support moving to new URL scheme.
   * Will be removed once analytics and brochure site transition to slug.
   *
   * If it's a new hotel, just copy hotelReferenceId here for now.
   *
   * @deprecated
   */
  code: z.string(),
  description: z.string().optional(),
  summaryDescription: z.string().optional(),

  location: z.object({
    name: z.string().optional(), // = locationName

    address: z.object({
      lines: z.array(z.string()).default([]),
      city: z.string(),
      state: z.string().optional(),
      postcode: z.string(),
      countryCode: z.string(),
    }),

    transport: z
      .array(
        z.object({
          mode: z.string().describe('PARKING'), // PARKING |
          title: z.string(),
          description: z.string(),
        })
      )
      .default([]),
    timezone: z.string().optional(),
    /**
     * For cross-selling other nearby hotels when the house is full. Key = slug.
     */
    nearbyHotels: z
      .array(z.object({ distanceKm: z.number(), hotelReferenceId: z.string() }))
      .optional(),
  }),

  urls: z.object({
    faq: z.string(),
    terms: z.string(),
    map: z.string().optional(),
    website: z.string(),
  }),

  contact: z.object({
    emails: z.object({
      info: z.string(),
      booking: z.string(),
      sales: z.string(),
    }),
    phone: z.string().optional(),
  }),

  content: z.object({
    activities: z.array(ActivityModel).default([]),
    restaurants: z.array(RestaurantModel).default([]),
    banners: z.array(HotelBanner).default([]),
    perks: z.array(HotelPerk).default([]),
    images: z.object({
      hero: ImageModel.optional(),
      map: ImageModel.optional(),
      header: ImageModel.optional(),
    }),
    charities: z
      .array(
        z.object({
          name: z.string(),
          description: z.string(),
          logo: ImageModel,
        })
      )
      .optional(),
  }),

  payments: z.object({
    currencyCode: z.enum(['USD', 'EUR', 'GBP', 'CHF', 'AED', 'DKK']), // will add an ISO list to validate against
    providers: z.object({
      stripe: z.object({
        publicKey: z.string(),
      }),
    }),

    /**
     * Which payment methods are supported?
     * This might be superceded by the work being done with Stripe Connect - ideally we
     * should be able to get it from them rather than defining here.
     */
    methods: z
      .array(
        z
          .string()
          .transform((str) => str.toLowerCase())
          .pipe(z.enum(['visa', 'amex', 'mastercard', 'discover', 'diners']))
      )
      .default([]),
  }),

  /**
   * Allows hotels to override a brand-level theme.
   */
  theme: z
    .object({
      key: z
        .enum([
          'hoxton',
          'gleneagles',
          'townhouse',
          'estelle',
          'sanderson',
          '25hours',
          '21c',
          'mamashelter',
          'morgansoriginals',
          'mondrian',
          'hyde',
        ])
        .optional(),
    })
    .optional(),

  /**
   * Does hotel offers add-ons.
   */
  addOnsEnabled: z.boolean().default(false),

  /**
   * Legacy - feel free to ignore if new taxes approach is decided
   */
  legacyTaxes: z.object({
    /**
     * Should we render room totals with VAT added? Example, UK.
     */
    displayRateWithVat: z.boolean(),

    /**
     * When querying for rooms, should we ask for a detailed tax breakdown?
     */
    isAdvanced: z.boolean(),

    /**
     * Translation key for the message that appears next to the total on the room search page.
     * @TODO: We should build a Zod schema for the dictionary structure. This should be strongly-typed.
     */
    includedTaxesMessageTranslationKey: z.enum([
      'occupancyAndSalesTax',
      'vat',
      'taxesAndFees',
      'vatAndCityTax',
      'vatTouristAndCityTax',
      'none',
    ]),
  }),
  /**
   * For hotels where City Tax cannot be correctly supplied by the PMS system.
   */
  cityTaxSummary: z.string().optional(),
  cityTaxDescription: z.string().optional(),
  cityTaxAmount: z.string().optional(),
  visibility: z
    .object({
      /**
       * Should this hotel appear in the search dropdown?
       */
      search: z.boolean().default(true),
    })
    .default({ search: true }),

  /**
   * Attributes relating to hotel operations.
   */
  operational: z.object({
    openingDate: z.string().optional(),

    /**
     * The default check-in time for bookings.
     */
    checkIn: z.string().optional(),

    /**
     * The default check-in time for bookings.
     */
    checkOut: z.string().optional(),

    /**
     * Which room occupancy configurations are supported in this hotel?
     */
    supportedRoomOccupancyConfigurations: z.array(v1RoomOccupancySchema),

    /**
     * Does this hotel have accessible rooms?
     */
    hasAccessibleRooms: z.boolean().default(false),
  }),

  /**
   * Rollout flag config
   */
  rollout: rolloutConfigurationSchema.optional(),

  paymentCancellationPolicy: z.object({
    summary: z.string(),
    /**
     * This may include handlebar-like template tags for contact email, etc.
     */
    description: z.string(),
  }),
  /**
   * Whether a hotel needs the child ages to be set when booking
   * @todo remove default value of true once the api supports this
   */
  requiresChildAges: z.boolean().default(true),

  /**
   * Attributes related to Manage My Booking.
   */
  manageMyBooking: z
    .object({
      baseUrl: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),
  destinationFeeSummary: z.string().optional(),
  destinationFeeDescription: z.string().optional(),
  destinationFeeAmount: z.string().optional(),
});
