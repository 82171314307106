import React, { useEffect } from 'react';

import { COOKIE_CONSENT_ALLOWED_DOMAINS } from '../allowed-domains.const';

export function ConsentManagerScript(props: { id: string }) {
  return (
    // eslint-disable-next-line @next/next/no-sync-scripts
    <script
      type="text/javascript"
      data-cmp-ab="1"
      src={`https://cdn.consentmanager.net/delivery/autoblocking/${props.id}.js`}
      data-cmp-host="b.delivery.consentmanager.net"
      data-cmp-cdn="cdn.consentmanager.net"
      data-cmp-codesrc="1"
    ></script>
  );
}

export function ConsentManagerButton(props: { children: React.ReactNode }) {
  return (
    <a
      href="#cmpscreen"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        __cmp('showScreen');
      }}
    >
      {props.children}
    </a>
  );
}

export function ConsentManagerSideEffects(props: { id: string }) {
  useEffect(() => {
    if (
      !window.cmp_block_ignoredomains ||
      !window.cmp_block_ignoredomains.length
    ) {
      window.cmp_block_ignoredomains =
        COOKIE_CONSENT_ALLOWED_DOMAINS as string[];
    }
  }, [props.id]);
  return null;
}
