import { useTheme } from '.';
import { EnnismoreThemeIcons } from '../ennismore-theme.interface';

export const useIcon = (key?: EnnismoreThemeIcons | string) => {
  const theme = useTheme();

  if (!key) return theme.icons.BULLET;

  return theme.icons[key] || theme.icons.BULLET;
};
