import { css } from '@emotion/react';
import localFont from 'next/font/local';
import Image from 'next/image';
import React from 'react';

import { useAvailabilityURLQuery } from '@/availability/hooks';
import { Box } from '@/box';
import { useHotel } from '@/hotel';
import { BodySmall, Caption, Text } from '@/ui/typography';

const disloyaltyFont = localFont({
  src: '../../../../../public/disloyalty/fonts/pp-right-slab-light.otf',
  display: 'swap',
});

const getDisloyaltyBannerConfig = (hotelId: string) => {
  switch (hotelId) {
    case 'hoxton.holborn-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_holborn&utm_content=banner&slug=the-hoxton-holborn',
      };
    case 'hoxton.vienna-vienna':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_vienna&utm_content=banner&slug=the-hoxton-vienna',
      };
    case 'barcelona':
    case 'hoxton.poblenou-barcelona':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_barcelona&utm_content=banner&slug=the-hoxton-poblenou-barcelona',
      };
    case 'amsterdam':
    case 'hoxton.herengracht-amsterdam':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_amsterdamherengracht&utm_content=banner&slug=the-hoxton-amsterdam-herengracht',
      };
    case 'hoxton.chicago-chicago':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_chicago&utm_content=banner&slug=the-hoxton-chicago',
      };
    case 'hoxton.downtown-losangeles':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_dtla&utm_content=banner&slug=the-hoxton-downtown-la',
      };
    case 'hoxton.portland-portland':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_portland&utm_content=banner&slug=the-hoxton-portland',
      };
    case 'hoxton.williamsburg-newyork':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_williamsburg&utm_content=banner&slug=the-hoxton-williamsburg-new-york',
      };
    case 'hoxton.southwark-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_southwark&utm_content=banner&slug=the-hoxton-southwark',
      };
    case 'paris':
    case 'hoxton.paris-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_paris&utm_content=banner&slug=the-hoxton-paris',
      };
    case 'hoxton.shoreditch-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_shoreditch&utm_content=banner&slug=the-hoxton-shoreditch-london',
      };
    case 'shepherds-bush':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_shepherdsbush&utm_content=banner&slug=the-hoxton-shepherds-bush-london',
      };
    case 'charlottenburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_berlin&utm_content=banner&slug=the-hoxton-charlottenburg',
      };
    case 'brussels':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_brussels&utm_content=banner&slug=the-hoxton-brussels',
      };
    case '25hours.theroyalbavarian-munich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Royal-Bavarian-booking&slug=25-hours-hotel-munich-the-royal-bavarian',
      };
    case '25hours.west-zurich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Zurich-West-booking&slug=25hours-hotel-zuerich-west',
      };
    case '25hours.beimmuseumquartier-vienna':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Museumsquartier-booking&slug=25hours-hotel-vienna-at-museums-quartier',
      };
    case '25hours.thecircle-cologne':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Circle-booking&slug=25hours-hotel-cologne-the-circle',
      };
    case '25hours.langstrasse-zurich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Zurich-Langstrasse-booking&slug=25-hours-hotel-zurich-langstrasse',
      };
    case '25hours.dastour-dusseldorf':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Tour-booking&slug=25hours-hotel-duesseldorf-das-tour',
      };
    case '25hours.thegoldman-frankfurt':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Goldman-booking&slug=25hours-hotel-frankfurt-the-goldman',
      };
    case '25hours.thetrip-frankfurt':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Trip-booking&slug=25hours-hotel-frankfurt-the-trip',
      };
    case '25hours.alteshafenamt-hamburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Altes-Hafenamt-booking&slug=25hours-hotel-hamburg-altes-hafenamt',
      };
    case '25hours.hafencity-hamburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Hafen-City-booking&slug=25-hours-hotel-hamburg-hafen-city',
      };
    case '25hours.bikiniberlin-berlin':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Bikini-Berlin-booking&slug=25-hours-hotel-bikini-berlin',
      };
    case '25hours.paperisland-copenhagen':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=paper-island-booking&slug=25hours-hotel-copenhagen-paper-island',
      };
    case 'morgansoriginals.sanderson-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=morgansoriginals&utm_medium=website&utm_campaign=sanderson-london-booking&slug=sanderson-london',
      };
    case 'morgansoriginals.st-martins-lane-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=morgansoriginals&utm_medium=website&utm_campaign=st-martins-lane-london-booking&slug=st-martins-lane-london',
      };
    case 'mamashelter.east-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-east-booking&slug=mama-shelter-paris-east',
      };
    case 'mamashelter.toulouse-toulouse':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=toulouse-booking&slug=mama-shelter-toulouse',
      };
    case 'mamashelter.roma-rome':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=roma-booking&slug=mama-shelter-roma',
      };
    case 'mamashelter.rennes-rennes':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=rennes-booking&slug=mama-shelter-rennes',
      };
    case 'mamashelter.prague-prague':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=prague-booking&slug=mama-shelter-prague',
      };
    case 'mamashelter.west-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-west-booking&slug=mama-shelter-paris-west',
      };
    case 'mamashelter.ladefense-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-la-defense-booking&slug=mama-shelter-paris-la-defense',
      };
    case 'mamashelter.marseille-marseille':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=marseille-booking&slug=mama-shelter-marseille',
      };
    case 'mamashelter.lyon-lyon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lyon-booking&slug=mama-shelter-lyon',
      };
    case 'mamashelter.luxembourg-luxembourg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=luxembourg-booking&slug=mama-shelter-luxembourg',
      };
    case 'mamashelter.losangeles-losangeles':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=los-angeles-booking&slug=mama-shelter-los-angeles',
      };
    case 'mamashelter.shoreditch-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=london-booking&slug=mama-shelter-london',
      };
    case 'mamashelter.lisboa-lisbon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lisboa-booking&slug=mama-shelter-lisboa',
      };
    case 'mamashelter.lille-lille':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lille-booking&slug=mama-shelter-lille',
      };
    case 'mamashelter.dijon-dijon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=dijon-booking&slug=mama-shelter-dijon',
      };
    case 'mamashelter.bordeaux-bordeaux':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=bordeaux-booking&slug=mama-shelter-bordeaux',
      };
    case 'mamashelter.belgrade-belgrade':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=belgrade-booking&slug=mama-shelter-belgrade',
      };
    default:
      return null;
  }
};

const transformHotelName = (hotelName: string, brandReferenceId: string) => {
  switch (brandReferenceId) {
    case '25hours':
      return hotelName.replace(/(?!25hours\b)\b\w+/gm, (substring) =>
        substring.toUpperCase()
      );
    default:
      return hotelName.toUpperCase();
  }
};

const appendSearchQueryParamsToDisloyaltyUrl = (
  url: string,
  query: ReturnType<typeof useAvailabilityURLQuery>
) => {
  // Proceed to include search params only if the query is fully valid
  if (query?.successType !== 'full') return url;

  const newUrl = new URL(url);

  // Transform our query to Disloyalty's format
  const transformedSearchQuery = {
    checkInDate: query.validData.from,
    checkOutDate: query.validData.to,
    adults: query.validData.rooms
      .reduce((count, roomOccupancy) => count + roomOccupancy.adults, 0)
      .toFixed(),
    children: query.validData.rooms
      .reduce((count, roomOccupancy) => count + roomOccupancy.children, 0)
      .toFixed(),
  };

  // Append query params
  for (const [name, value] of Object.entries(transformedSearchQuery)) {
    newUrl.searchParams.append(name, value);
  }

  return newUrl.href;
};

export function DisloyaltyBanner({ hotelId }: { hotelId: string }) {
  const config = getDisloyaltyBannerConfig(hotelId);
  const hotel = useHotel(hotelId);

  const query = useAvailabilityURLQuery();

  if (!config || !hotel) return null;

  const disloyaltyUrl = appendSearchQueryParamsToDisloyaltyUrl(
    config.link,
    query
  );

  return (
    <Box
      display={'flex'}
      position={'relative'}
      flexDirection={['column', 'row']}
      justifyContent={['start', 'space-between']}
      alignItems={'center'}
      backgroundColor={'#c5ff98'}
      height={['auto', 208]}
      padding={['40px 30px 80px', '0 20px 0', '0 40px 0']}
      gap={[24, 16, 32]}
      css={css`
        color: #3d3937;
      `}
    >
      {/* TODO: this is a temporary sale ending on September 2nd, 2024; after that remove this and uncomment the logo below */}
      <Box width={[70, 98]} height={[70, 98]}>
        <Image
          src="/disloyalty/sale-badge.svg"
          width={98}
          height={98}
          alt="Sale now on badge"
        />
      </Box>

      {/* <Box
        display={['flex']}
        justifyContent={'center'}
        alignItems={'center'}
        width={78}
        height={78}
        css={css`
          border: 1px solid #143c21;
          border-radius: 50%;
        `}
      >
        <Image
          src="/disloyalty/disloyalty-logo.svg"
          width={30}
          height={33}
          alt="Disloyalty logo"
        />
      </Box> */}

      <Box marginRight={[0, 'auto']} textAlign={['center', 'left']}>
        <div className={`${disloyaltyFont.className}`}>
          <Text style="heading5" uppercase fontFamily="inherit" inheritColor>
            {'Want '}
            <u>{config.justOpened ? 'up to 50% off' : '20% off'}</u>
            {' your stay at'}
          </Text>
          <Text style="heading5" fontFamily="inherit" inheritColor>
            {transformHotelName(hotel.name, hotel.brandReferenceId)}
            {'?'}
          </Text>
        </div>
      </Box>
      <Box height={[40, 57]}>
        <a
          href={disloyaltyUrl}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100%;
            padding-right: 17px;
            padding-left: 17px;
            color: #edeadd;
          `}
          rel="noopener noreferrer"
          target="_blank"
        >
          <BodySmall uppercase noWrap inheritColor>
            {'Join Dis-Loyalty'}
          </BodySmall>
        </a>
      </Box>
      <Box
        position={'absolute'}
        width={'full'}
        css={css`
          bottom: 0;
          left: 0;
        `}
        padding={['0 40px 12px', '0 24px 18px']}
      >
        <Caption
          css={css`
            text-align: center;
          `}
          inheritColor
        >
          {
            'Booking must be made at least 7 days in advance. Discount cannot be used in conjunction with any other offers. T&Cs apply.'
          }
        </Caption>
      </Box>
    </Box>
  );
}
