import React from 'react';

import { Box, Flex } from '@/box';
import { CurrencyPreview, useCurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';
import type { HotelConfiguration } from '@/hotel';
import { useTranslation } from '@/i18n';
import { useSpaceMatchingKey } from '@/ui/theme';
import { BodyLarge, BodySmall, Caption } from '@/ui/typography';

interface RoomTotalProps {
  isTotalInclusiveOfAllTaxes: boolean;
  totalWithTaxes?: IFinancialAmountInstance;
  // The message to display for included taxes (eg. (Incl. VAT))
  includedTaxesMessageTranslationKey: HotelConfiguration['legacyTaxes']['includedTaxesMessageTranslationKey'];
  price: IFinancialAmountInstance;
}

export const RoomTotal = (props: RoomTotalProps) => {
  const margin = useSpaceMatchingKey('xxs');
  const { t } = useTranslation(['bookingSummary', 'taxesShortDescription']);
  const accessiblePrice = useCurrencyPreview({ amount: props.price });
  const isSubtotal = props.includedTaxesMessageTranslationKey === 'none';

  const accessibleLabel = isSubtotal
    ? t('bookingSummary:labels.subtotal', {
        price: accessiblePrice,
      })
    : t('taxesShortDescription:accessibleRoomTotalExplainer', {
        price: accessiblePrice,
        totalWithTaxes: props.totalWithTaxes,
        includedTaxesMessage: `$t(taxesShortDescription:${props.includedTaxesMessageTranslationKey}Accessible)`,
        context: props.isTotalInclusiveOfAllTaxes ? '' : 'withAdvancedTaxes',
      });
  return (
    <div>
      <Flex
        alignItems="baseline"
        flexWrap="wrap"
        maxWidth={380}
        aria-label={accessibleLabel}
      >
        {!isSubtotal && (
          <Box marginRight={margin}>
            <BodySmall color="secondary300">
              {t('bookingSummary:labels.total')}
            </BodySmall>
          </Box>
        )}
        <Flex alignItems="baseline" flexDirection="row" flexWrap="wrap">
          <Box marginRight={margin}>
            <BodyLarge testId="room-total-price">
              <CurrencyPreview amount={props.price} />
            </BodyLarge>
          </Box>
          {props.isTotalInclusiveOfAllTaxes && !isSubtotal && (
            <Caption noWrap color="secondary300" testId="room-total-taxes">
              {`(${t(
                `taxesShortDescription:${props.includedTaxesMessageTranslationKey}`
              )})`}
            </Caption>
          )}
        </Flex>
        {!props.isTotalInclusiveOfAllTaxes ? (
          <Caption noWrap color="secondary300" testId="room-total-taxes">
            <>
              {`(${t(
                `taxesShortDescription:${props.includedTaxesMessageTranslationKey}`
              )}): `}
              <CurrencyPreview amount={props.totalWithTaxes} />
            </>
          </Caption>
        ) : undefined}
      </Flex>
    </div>
  );
};

export default RoomTotal;
