import { css } from '@emotion/react';
import type { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';
import Image from 'next/image';
import React, { useEffect, useMemo } from 'react';

import { Box, Flex } from '@/box';
import { useCurrencyPreview } from '@/currency-preview';
import { useHotel } from '@/hotel';
import { getAltForId } from '@/hotel/components/TransportOption.component';
import { useTranslation } from '@/i18n';
import { Button } from '@/ui/controls';
import { Card, Divider } from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { useSpaceMatchingKey, useTheme } from '@/ui/theme';
import { Text } from '@/ui/typography';

import { useBedroomAvailability } from '../hooks';

interface HotelAvailabilityCardProps extends V1GetAvailabilityRequest {
  onButtonClick: () => void;
  locale: { language: string };
  /**
   * Called when rooms are found to be available.
   * This is (arguably a hack) to support conditionally rendering the title without having to raise the API check to the parent component.
   */
  onRoomsAvailable: () => void;
  distance: string;
}

/**
 * Queries for availability at the given hotel, then renders a card with title, travel info and description for cross-selling.
 */
export const NearbyHotelAvailabilityCard: React.FC<
  HotelAvailabilityCardProps
> = (props) => {
  const { t } = useTranslation(['findUs', 'search']);
  const hotel = useHotel(props.hotelReferenceId);
  const { icons } = useTheme();
  const xsSpacing = useSpaceMatchingKey('xs');
  const xlSpacing = useSpaceMatchingKey('xl');
  const { data } = useBedroomAvailability(props);
  const cheapestRate = useMemo(
    () => data?.cheapestAvailableRoom?.cheapestRate,
    [JSON.stringify(data)]
  );

  const localisedCheapestRatePrice = useCurrencyPreview({
    amount: cheapestRate,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    // If we've loaded data, and the hotel isn't fully booked
    if (!data.isHotelFullyBooked) {
      props.onRoomsAvailable();
    }
  }, [data?.isHotelFullyBooked, props.onRoomsAvailable]);

  if (!data || !hotel) {
    return null;
  }

  if (data.isHotelFullyBooked) {
    return null;
  }

  return (
    <Card p="none">
      <Flex
        gap={['26px', '30px']}
        flexDirection={['column', 'row']}
        justifyContent={['center']}
        padding={[xsSpacing, xlSpacing]}
      >
        <Box width={['100%', '330px']}>
          <Image
            src={hotel.content.images.hero?.source ?? ''}
            alt={hotel.content.images.hero?.altText ?? ''}
            width={330}
            height={330}
            style={{ aspectRatio: '1', objectFit: 'cover', width: '100%' }}
          />
        </Box>
        <Box flex={'1'}>
          <Stack s="xs" height="100%">
            <Flex
              justifyContent={'space-between'}
              alignItems={'baseline'}
              flexWrap={'wrap'}
              flexDirection={['column', 'row']}
              gap={['20px', '0']}
            >
              <Text style="heading5">{hotel.name}</Text>
              <Text style="bodySmall" inline textAlign="right">
                {`(${t('findUs:distance', {
                  distance: props.distance,
                })})`}
              </Text>
            </Flex>
            {hotel.summaryDescription ? (
              <>
                <Divider />
                <Text style="bodySmall">{hotel.summaryDescription}</Text>
                <Divider />
              </>
            ) : null}
            <ul>
              {hotel.location.transport
                // Parking shouldn't be rendered
                .filter((transportOption) => transportOption.mode !== 'PARKING')
                .map((transportOption) => (
                  <li
                    key={transportOption.title}
                    css={css`
                      padding-bottom: 8px;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Box width={['24px']} marginRight={'4px'} flexShrink={'0'}>
                      <Image
                        src={icons[transportOption.mode] ?? icons.BULLET}
                        alt={getAltForId(transportOption.mode)}
                        width={24}
                        height={24}
                      />
                    </Box>

                    <Text style="bodySmall">
                      {transportOption.title} {'-'}{' '}
                      {transportOption.description}
                    </Text>
                  </li>
                ))}
            </ul>
            <Box
              maxHeight={'48px'}
              alignSelf={'end'}
              marginBottom={[xsSpacing, '0px']}
            >
              <Button onClick={props.onButtonClick}>
                {t('search:nearby.hotels.ctaLabel', {
                  price: localisedCheapestRatePrice,
                })}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Card>
  );
};
