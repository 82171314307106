import { V1Guest } from '@ennismore/ows-api-client';
import { Paymentv1PaymentMethod } from '@ennismore/payment-stripe-api-client';
import { z } from 'zod';

import { v2BookingStatusSchema } from '@/api/clients/ohip';
import { IBookingAddOnInstance } from '@/booking-add-ons/models';
import {
  BookingCotOrBed,
  BookingDayTimePart,
} from '@/em-api-client-typescript-fetch';
import { sumFinancialAmount } from '@/finance/helpers/sumFinancialAmount.func';
import { getFlexyTimeRange } from '@/flexy-time';
import {
  getLegacyTotalAdultGuestCount,
  getLegacyTotalChildGuestCount,
  getLegacyTotalGuestCount,
} from '@/hotel/helpers/occupancy-type.helpers';

import { PaymentTime } from '../interfaces/PaymentTime.interface';
import { BookingMetaDataModel } from './booking-metadata.model';
import {
  ChargeBreakdownModel,
  IChargeBreakdownInstance,
} from './charge-breakdown.model';
import { RoomStayModel } from './room-stay.model';

export type IBooking = typeof BookingModel;
export type IBookingSnapshot = z.input<IBooking>;
export type IBookingInstance = z.infer<IBooking>;

export const BookingModel = z
  .object({
    id: z.string(),
    /**
     * 1 room stay per bedroom on the booking
     */
    roomStay: z.array(RoomStayModel).min(1),
    specialAssistance: z.string().optional(),
    dog: z.boolean().default(false),
    flexyTime: z.boolean().default(false),
    isCancellable: z.boolean().default(false),
    cotOrBed: z.nativeEnum(BookingCotOrBed).optional(),
    checkin: z.nativeEnum(BookingDayTimePart).optional(),
    checkout: z.nativeEnum(BookingDayTimePart).optional(),
    /**
     * 1 totalChargeBreakdown per booking
     */
    totalChargeBreakdown: z.array(ChargeBreakdownModel),
    metaData: BookingMetaDataModel.optional(),
    status: v2BookingStatusSchema.default('NONE'),
    payment: z.object({
      method: z.nativeEnum(Paymentv1PaymentMethod),
      secret: z.string(),
      connectedAccountId: z.string().optional(),
    }),
  })
  .transform((self) => ({
    ...self,
    get firstTotalChargeBreakdown(): IChargeBreakdownInstance {
      if (self.totalChargeBreakdown.length < 1) {
        throw new Error('No totalChargeBreakdown items found in booking');
      }

      return self.totalChargeBreakdown[0];
    },
  }))
  .transform((self) => ({
    ...self,
    get paymentTime(): PaymentTime {
      return self.payment?.method === 'PAY_NOW' ||
        self.firstTotalChargeBreakdown.deposit
        ? PaymentTime.PayNow
        : PaymentTime.PayLater;
    },
  }))
  .transform((self) => ({
    ...self,
    get isDepositDueNow() {
      return self.paymentTime === PaymentTime.PayNow;
    },
  }))
  .transform((self) => ({
    ...self,
    get firstRoomStay() {
      return self.roomStay[0];
    },
    get isConfirmed() {
      return self.status === 'CONFIRMED' || self.status === 'COMPLETED';
    },
    get isCancelled() {
      return (
        self.status === 'CANCELLED' ||
        self.status === 'CANCELLED_BY_USER' ||
        self.status === 'CANCELLED_BY_PMS' ||
        self.status === 'CANCEL_RECEIVED'
      );
    },
  }))
  .transform((self) => ({
    ...self,
    get pmsId() {
      return self.firstRoomStay.operaId;
    },
    get numberOfRooms() {
      return self.roomStay.length;
    },
    get addOns() {
      const addOns: IBookingAddOnInstance[] = [];
      // Iterate through rooms and count all add-ons.
      self.roomStay.forEach((stay) => {
        stay.firstRoomDetail.addOnsDetail?.forEach((addOn) => {
          const index = addOns.findIndex((a) => a.id === addOn.id);
          if (index > -1) {
            // Don't count request quantity for the checkbox add-on,
            // it has to remain 1 no matter how many rooms are being booked.
            if (addOns[index].displayType !== 'ADD_ON_DISPLAY_TYPE_TOGGLE') {
              addOns[index].requestedQuantity += addOn.requestedQuantity;
            }
            addOns[index].calculatedQuantity += addOn.calculatedQuantity;
            // The following summed amounts are only used for tracking purposes
            addOns[index].cost.amountAfterTax = sumFinancialAmount(
              addOns[index].cost.amountAfterTax,
              addOn.cost.amountAfterTax
            );
            addOns[index].cost.amountBeforeTax = sumFinancialAmount(
              addOns[index].cost.amountBeforeTax,
              addOn.cost.amountBeforeTax
            );
            addOns[index].cost.amountSubtotalDisplay = sumFinancialAmount(
              addOns[index].cost.amountSubtotalDisplay,
              addOn.cost.amountSubtotalDisplay
            );
          } else
            addOns.push({
              // We don't want to mutate base add-on by mistake!
              ...structuredClone(addOn),
              requestedQuantity:
                addOn.displayType === 'ADD_ON_DISPLAY_TYPE_TOGGLE'
                  ? 1
                  : addOn.requestedQuantity,
            });
        });
      });
      return addOns;
    },
    get totalNumberOfNights(): number {
      return self.firstRoomStay.numberOfNights;
    },
    get isSpecialAssistanceRequested(): boolean {
      return self.specialAssistance === 'requested';
    },
    get childBedTypeText() {
      switch (self.cotOrBed) {
        case BookingCotOrBed.BED:
          return 'Bed';
        case BookingCotOrBed.COT:
          return 'Cot';
      }
      return undefined;
    },
    get checkInAfter(): Date | undefined {
      if (!self.checkin) {
        return undefined;
      }
      return getFlexyTimeRange(self.checkin).start;
    },
    get checkOutBefore(): Date | undefined {
      if (!self.checkout) {
        return undefined;
      }
      return getFlexyTimeRange(self.checkout).end;
    },
    /**
     * Returns a single room image representative of the booking for stay summary card.
     */
    get heroRoomImage() {
      return self.firstRoomStay.firstRoomDetail.images[0];
    },
  }))
  .transform((self) => ({
    ...self,
    /**
     * Is it possible to add a child bed / cot to this booking?
     */
    get isChildBedSelectable() {
      return self.roomStay.some(
        (stay) => stay.roomGuest === V1Guest.TwoAdultOneChild
      );
    },
    get totalGuestCount() {
      return getLegacyTotalGuestCount(
        ...self.roomStay.map((stay) => stay.roomGuest || '')
      );
    },
    get totalAdultCount() {
      return getLegacyTotalAdultGuestCount(
        ...self.roomStay.map((stay) => stay.roomGuest || '')
      );
    },
    get totalChildCount() {
      return getLegacyTotalChildGuestCount(
        ...self.roomStay.map((stay) => stay.roomGuest || '')
      );
    },
  }));
