import { z } from 'zod';

export const rolloutConfigurationSchema = z.object({
  /**
   * Rollout status
   */
  status: z.enum(['always-fallback', 'never-fallback', 'check']).nullable(),
  /**
   * Fallback provider
   */
  provider: z.object({
    key: z
      .enum([
        'accor-all',
        'synxis',
        'travelclick',
        'verb-mamashelter',
        'verb-ennismore',
      ])
      .nullable(),
    fallbackHotelId: z.string().nullable(),
    redirectUrl: z.string().nullable(),
    fallbackSearchParamOverrides: z
      .object({
        hotelCode: z.string().optional(),
      })
      .optional(),
  }),
});

export type RolloutConfiguration = z.infer<typeof rolloutConfigurationSchema>;
