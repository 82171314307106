import React, { useContext, useState } from 'react';

import { useKeyPress } from '@/common/hooks';
import { Spacer } from '@/ui/spacing';
import { Heading } from '@/ui/typography';

import {
  AVAILABLE_FEATURES,
  AvailableFeatureKeys,
  Feature,
} from '../constants/available-features.const';
import { FeatureSwitchContext } from '../contexts/feature-switch.context';
import FeatureSwitch from './FeatureSwitch.component';
import FeatureSwitchUIModal from './FeatureSwitchUIModal.component';
import { ThemeOverrideSwitcher } from './ThemeOverrideSwitcher.component';

export const FeatureSwitchUIModalContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { enabledFeatures, setEnabledFeatures } =
    useContext(FeatureSwitchContext) || {};

  const handler = function (this: Document, event: KeyboardEvent) {
    if (
      (event.metaKey || event.ctrlKey) &&
      event.shiftKey &&
      event.key.toLowerCase() === 'f'
    ) {
      setIsOpen((current) => !current);
    }
  };

  useKeyPress(handler);

  const handleToggle = (key: AvailableFeatureKeys) => {
    if (enabledFeatures.includes(key)) {
      setEnabledFeatures((currentKeys) => currentKeys.filter((k) => k !== key));
      return;
    }

    setEnabledFeatures((currentKeys) => [...currentKeys, key]);
  };

  return (
    <FeatureSwitchUIModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      features={Object.keys(AVAILABLE_FEATURES).map((key) => {
        const feature: Feature = AVAILABLE_FEATURES[key];

        return (
          <FeatureSwitch
            key={key}
            name={feature.name || feature.id}
            description={feature.description}
            onButtonClick={() => handleToggle(feature.id)}
            isEnabled={enabledFeatures.includes(feature.id)}
          />
        );
      })}
      after={
        <React.Fragment>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <Heading style="heading5" as="h5">
            Theming
          </Heading>
          <Spacer s="xs" />
          <ThemeOverrideSwitcher />
        </React.Fragment>
      }
    />
  );
};

export default FeatureSwitchUIModalContainer;
